const GLOBAL_CONSTANT = {
    "API_URL": "https://api.cybersmartiesusa.com",
    "CHAT_API": "https://api.chat.cybersmartiesusa.com",
    "TIME_ZONE": "America/Los_Angeles",
    "OFFLINE": "false",
    "TEXT_SPEECH_API_URL": "https://aidennvz09.execute-api.eu-west-1.amazonaws.com",
}


export default GLOBAL_CONSTANT;
